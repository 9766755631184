import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./assets/css/tailwind.output.css";
import App from "./App";
import { SidebarProvider } from "./context/SidebarContext";
import ThemedSuspense from "./components/ThemedSuspense";
import { Windmill } from "@windmill/react-ui";
import * as serviceWorker from "./serviceWorker";
import { UserProvider } from "./context/UserContext";

const theme = {
  modal: {
    base: "w-9/12 px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4",
  },
};

ReactDOM.render(
  <UserProvider>
    <SidebarProvider>
      <Suspense fallback={<ThemedSuspense />}>

        {/* <Windmill theme={theme} usePreferences> */}
        <Windmill theme={theme} >

          <App />
        </Windmill>
      </Suspense>
    </SidebarProvider>
  </UserProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
