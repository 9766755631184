import http, {httpV2 } from "./axios";
// import react,{ useContext } from "react";
// import { UserContext } from './context/UserContext';

const config = {
  headers: {
    "content-type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    Authorization: localStorage.getItem("token") || "",
  },
};

// if(localStorage.getItem("token"))
// {
//   config.headers.Authorization=localStorage.getItem("token");
//   console.log("config added"+config);
// }

console.log(config);

// const pageHeading=useContext(UserContext);

export function startAuth(mobile, isEmail, emailId) {
  console.log("Entered startAuth");
  http
    .post(
      `mobileSignupLogin/`,
      {
        mobile: mobile,
        user_type: "STUDENT",
        otp_on_email: isEmail,
        email: emailId,
      },
      config
    )
    .then((result) => {
      result = result.data;
      if (result.status == 200) {
        console.log("LOGIN", result.response);
        localStorage.setItem("token", "");
      } else {
      }
    })
    .catch((err) => {
      console.log(err.message);
      console.log(err);
      //   dispatch(authFailed(err.message));
    });
}

// TTS functionality
// export function getTTS(content) {
//   console.log("This has now entered getts", content);
//   return httpTts
//     .post(
//       `ttslogic/demovoice/`,
//       {
//         myvoice: content,
//       },
//       {
//         headers: {
//           "content-type": "application/json",
//           "X-Requested-With": "XMLHttpRequest",
//         },
//       }
//     )
//     .then((result) => {
//       console.log("resssult", result);
//       if (result.status == 200) {
//         return result.data;
//         // console.log('Data->', result.data);
//         // result && console.log('Url->', result.data.audiourl);
//         // // Audio audio=new Audio(result.data);
//         // // (new Audio(result.data)).play();
//         // result.data && setAudio(new Audio(result.data.audiourl));
//         // setMarkWords(result.data.timepoints)
//         // // setMp3Url(URL.createObjectURL(result.data));
//         // setIsPlaying(true);
//       } else {
//         return "FAIL";
//       }
//     })
//     .catch((err) => {
//       console.log(err.message);
//       console.log(err);
//       return "FAIL";
//       //   dispatch(authFailed(err.message));
//     });
// }

// TTS API from AWS S3
export function getOnlineTts(param) {
  console.log("This has now entered getts-> ", param);
  return http
    .post(`generate_tts/`, {
      myvoice: param.myvoice,
      chapter_id: param.chapter_id,
      part_id: param.part_id,
    })
    .then((result) => {
      console.log("resssult", result);
      if (result.status == 200) {
        return result.data;
      } else {
        return "FAIL";
      }
    })
    .catch((err) => {
      console.log(err.message);
      console.log(err);
      return "FAIL";
      //   dispatch(authFailed(err.message));
    });
}

// export function getGptData(content, mode) {
//   console.log("GPTMODEL", "This has now entered chatGPT", content);
//   return httpTts
//     .post(
//       `gptlogic/gpttext/`,
//       {
//         text: content,
//         mode: mode,
//       },
//       {
//         headers: {
//           "content-type": "application/json",
//           "X-Requested-With": "XMLHttpRequest",
//         },
//       }
//     )
//     .then((result) => {
//       console.log("resssult", result);
//       if (result.status == 200) {
//         console.log("Lets test ChatGPT", result.status);
//         return result.data;
//         // console.log('Data->', result.data);
//         // result && console.log('Url->', result.data.audiourl);
//         // // Audio audio=new Audio(result.data);
//         // // (new Audio(result.data)).play();
//         // result.data && setAudio(new Audio(result.data.audiourl));
//         // setMarkWords(result.data.timepoints)
//         // // setMp3Url(URL.createObjectURL(result.data));
//         // setIsPlaying(true);
//       } else {
//         return "FAIL";
//       }
//     })
//     .catch((err) => {
//       console.log(err.message);
//       console.log(err);
//       return "FAIL";
//       //   dispatch(authFailed(err.message));
//     });
// }

export function verifyOTP(mobile, otp) {
  // return (dispatch) => {
  //   dispatch(authStarted());
  console.log("Reached verifyOTP");
  return http
    .post(
      `studentotpverification/`,
      {
        mobile: mobile,
        otp: otp,
      },
      config
    )
    .then((result) => {
      console.log("CheckOTP", result);
      result = result.data;
      if (result.status === 200) {
        console.log("200 Status achieved");
        console.log(result);
        localStorage.setItem("token", result.response.token);
        console.log("token is ->" + result.response.token);

        config.headers.Authorization = result.response.token;
        //   getStudentList().then(d=>{
        //    return d
        //  });

        return getStudentList();

        //return result.response.token;

        // getStudentList();
        // dispatch(
        //   authSuccess({
        //     ...store.getState().auth.user,
        //     ...result.response,
        //     //theme: {name: 'Purple'},
        //   }),
        // );
      } else {
        // dispatch(authFailed(result.message));
        console.log("maybe something went wrong");
        return "FAIL";
      }
    })
    .catch((err) => {
      //   dispatch(authFailed('Something went wrong'));
      console.log("Verification OTP failed");
      console.log(err);
    });
  // };
}

export function getTodayActivity() {
  return http
    .get("getdailyactivity/", config)
    .then((result) => {
      result = result.data;
      console.log("Activity", JSON.stringify(result));

      if (result && result.status === 200) {
        return result;
      }
    })
    .catch((error) => {
      console.log("Activity", "Error");
    });
}

export function getStudentList() {
  // return async (dispatch) => {
  //   const appStudents = await getStudentsFromStore();
  //   if (appStudents.length > 0) {
  //     dispatch(getStudentsStarted());
  //     dispatch(getStudentsSuccess(appStudents));
  //     return;
  //   }
  // dispatch(getStudentsStarted());
  return http
    .get(`getAllStudentProfile/`, config)
    .then((result) => {
      result = result.data;
      console.log("getStudentList", result);
      if (result && result.status === 200) {
        if (result.response.studentProfileDetails.length < 1) {
          console.log("Entered parentProfileStatus", result);
          // pageHeading.changeLogin(true);
          // dispatch(getStudentsFailed('Please create account first'));
          // dispatchNav(
          //   CommonActions.reset({
          //     index: 0,
          //     routes: [{name: 'CreateAccount'}],
          //   }),
          // );
          // localStorage.setItem("students", "");
          return "SUCCESS";
        } else {
          console.log(
            "Entered else part->Profile!!",
            result.response.studentProfileDetails
          );

          localStorage.setItem(
            "students",
            JSON.stringify(result.response.studentProfileDetails)
          );

          return "SUCCESS";

          // AsyncStorage.setItem(
          //   'students',
          //   JSON.stringify(result.response.studentProfileDetails),
          // );
          // dispatch(getStudentsSuccess(result.response.studentProfileDetails));
        }
      } else {
        console.log("Status is not 200" + result.status);

        // dispatch(getStudentsFailed(result.message));
      }
    })
    .catch((error) => {
      console.log("Some error" + error);

      // NetInfo.fetch().then((info) => {
      //   const {isConnected} = info;
      //   if (!isConnected) {
      //     AsyncStorage.getItem('students').then((students) => {
      //       dispatch(getStudentsSuccess(JSON.parse(students)));
      //     });
      //   } else {
      //     dispatch(getStudentsFailed(error.message));
      //   }
      // });
    });
  // };
}

export function getStudentSubjects(params) {
  console.log(config.headers.Authorization);

  return http
    .post(`getAllSubjectByClassMed/`, params, config)
    .then((result) => {
      result = result.data;
      if (result && result.status == 200) {
        console.log("200 result for subject->" + result);

        return result;
        // const chapterContents = syncChapterContents(
        //   param.class_id,
        //   result.response,
        //   // store.getState().user.study,
        // );
        // dispatch(setChapterContent(chapterContents));
        // syncWithLocalStorage(param.class_id, result.response);
        // dispatch(getStudentSubjectsSuccess(result.response));
      } else {
        console.log("Something is wrong");
        // dispatch(getStudentSubjectsFailed('Something is went wrong'));
      }
    })
    .catch((e) => console.log("Error:" + e));
}

export const getChapters = (param) => {
  // return (dispatch) => {
  // dispatch(getChaptersStarted());
  // const chaptersFromStore = getChaptersFromStore(
  //   param.class_id,
  //   param.subject_id,
  //   store.getState().user.study,
  // );
  // if ((chaptersFromStore.length > 0 && !param.forceRefresh) || false) {
  //   dispatch(getChaptersSuccess(chaptersFromStore));
  //   return;
  // }
  return http
    .post(`getAllChapters/`, param, config)
    .then((result) => {
      result = result.data;
      if (result && result.status == 200) {
        console.log("Entered 200 for chapters");

        return result;
        // const chapterContent = syncChaptersWithStore(
        //   param,
        //   result.response,
        //   store.getState().user.study,
        // );
        // dispatch(setChapterContent(chapterContent));
        // addChapterToLocalStorage(param, result.response);
        // dispatch(getChaptersSuccess(result.response));
      } else {
        // dispatch(getChaptersFailed('Something is went wrong'));
      }
    })
    .catch((err) => {
      console.log("Error occured chapters get", err);

      // NetInfo.fetch().then((info) => {
      //   const {isConnected} = info;
      //   if (!isConnected) {
      //     AsyncStorage.getItem('study').then((study) => {
      //       study = JSON.parse(study);
      //       const classIndex = study.findIndex(
      //         (study) => study.class_id == param.class_id,
      //       );
      //       if (classIndex > -1) {
      //         const subjectIndex = study[classIndex].subjects.findIndex(
      //           (sub) => sub.subject_id == param.subject_id,
      //         );
      //         const chapters =
      //           study[classIndex].subjects[subjectIndex].chapters || [];
      //         dispatch(getChaptersSuccess(chapters));
      //       }
      //     });
      //   } else {
      //     dispatch(getChaptersFailed(err.message));
      //   }
      // });
    });
  // };
};

export const getChapterPreview = (param) => {
  // return (dispatch) => {
  // dispatch(chapterPreviewStarted());
  return http
    .post(`chapterPreview/`, param, config)
    .then((result) => {
      result = result.data;
      if (result && result.status === 200) {
        console.log("The result is 200");
        console.log(result);

        return result;
        // dispatch(
        //   chapterPreviewSuccess({
        //     ...result.response,
        //     chapter_parts: [...result.response.chapter_parts, {}],
        //   }),
        // );

        // const contentChapters = addContentToChapterStudy(
        //   {
        //     ...result.response,
        //     chapter_parts: [...result.response.chapter_parts, {}],
        //   },
        //   store.getState().user.study,
        // );

        // dispatch(setChapterContent(contentChapters));
      } else {
        // dispatch(chapterPreviewFailed('Something went wrong'));
      }
    })
    .catch((err) => {
      // dispatch(chapterPreviewFailed(err.message));
    });
  // };
};

const getContentFromUrl = (data) => {
  return fetch(data.content || data, {
    method: "GET",
    headers: new Headers({
      "content-type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    }),
  });
};

export const getChapterPartContentNew = (data) => {
  return http.post(`v2/getpartcontent/`, data, config).then(async (result) => {
    if (result && result.data) {
      console.log("Part is ->" + JSON.stringify(result));
      console.log("Part is ->" + JSON.stringify(result.data));
      let { response } = result.data;
      console.log(response);
      const chapterContent = await getContentFromUrl(response);
      const contentTrans = await chapterContent.json();
      response.content = contentTrans;
      result.data.response = response;
    }
    console.log("This part" + result);
    return result;
  });
};

export const getMCQForPart = (param) => {
  return httpV2
    .get(`mcq/${param}/get_part_mcq_tests/`, config)
    .then(async (result) => {
      console.log("Fetched Mcq data");
      console.log(result.data);
      console.log(result.data.data[0].question_url);

      let obj = {};
      let chapterContent;
      let contentTrans;
      if (result.data && result.data.data.length > 0) {
        chapterContent = await getContentFromUrl(
          result.data.data[0].question_url
        );
        contentTrans = await chapterContent.json();
        obj = {
          id: result.data.data,
          result: JSON.parse(contentTrans),
        };
        console.log(obj);
        return obj;
      }
    });
};

export const getBookmarkData = (params) => {
  return http
    .post(`getStudentBookmarks/`, params, config)
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("The result is 200");
        console.log(result);

        return result;
      } else {
        console.log("Bookmark", "Get Bookmark Failed");
      }
    });
};

export const deleteBookmark = (bookmarkId) => {
  return http
    .delete(`deletebookmark/${bookmarkId}/`, config)
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("The result is 200");
        console.log(result);

        return result.status;
      } else {
        console.log("Bookmark Delete", "Unable to Delete");
      }
    });
};

export const bookmarkChapter = (params) => {
  return http
    .post(`creatStudentBookmark/`, params, config)
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("The result is 200");
        console.log(result);

        return result;
      } else {
        console.log("Bookmark", "Create Bookmark Failed");
      }
    });
};

export const getLeaderBoardData = (params) => {
  return http
    .post(`getstudenttopperboard/`, params, config)
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("LeaderBoard", "The result is 200");
        console.log(result);

        return result;
      } else {
        console.log("LeaderBoard", "Create Bookmark Failed");
      }
    });
};

export const getStudentDistricts = (params) => {
  return http
    .post("getstudentdistrict/", params, config)
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("District", "The result is 200");
        console.log("District", result);

        return result;
      } else {
        console.log("District", "Error");
      }
    });
};

export const getStudentTals = (params) => {
  return http.post("getstudentblock/", params, config).then(async (result) => {
    if (result && result.status === 200) {
      console.log("Taluka", "The result is 200");
      console.log("Taluka", result);

      return result;
    } else {
      console.log("Taluka", "Error");
    }
  });
};

export const getClasses = (params) => {
  return http
    .post("getClassesByDistrict/", params, config)
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("Classes", "The result is 200");
        console.log("Classes", result);

        return result;
      } else {
        console.log("Classes", "Error");
      }
    });
};

export function getWebTimeSpend() {
  return http
    .get("get_web_time_spend/", config)
    .then((result) => {
      console.log("GetWebTimeSpend-before", JSON.stringify(result));
      result = result.data;
      console.log("GetWebTimeSpend", JSON.stringify(result));

      if (result && result.success) {
        console.log("GetWebTimeSpend-2", result);
        return result;
      }
    })
    .catch((error) => {
      console.log("GetWebTimeSpend", "Error");
    });
}

export const sendWebTimeSpend = (params) => {
  return http.post("web_time_spend/", params, config).then(async (result) => {
    if (result && result.success) {
      console.log("SendWebTimeSpend", "The result is success");
      console.log("SendWebTimeSpend", result);

      return result;
    } else {
      console.log("SendWebTimeSpend", "Error");
    }
  });
};
export const getSchoolInfo = (params) => {
  return http
    .post("studentsummaryblockwise/", params, config)
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("StudentSummary", "The result is 200");
        console.log("StudentSummary", result);

        return result;
      } else {
        console.log("Classes", "Error");
      }
    });
};

export const getClassmates = (params) => {
  return http
    .post("studentlist_limited_data/", params, config)
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("Classmates", "The result is 200");
        console.log("Classmates", result);

        return result;
      } else {
        console.log("Classmates", "Error");
      }
    });
};

export const getStudentsForExcel = (params) => {
  return http
    .post("student_limited_list_csv/", params, config)
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("csvdata", "The result is 200");
        console.log("csvdata", result);

        return result;
      } else {
        console.log("csvdata", "Error");
      }
    });
};

export const getSchoolDetails = (param) => {
  return http
    .get(`get_school_details/${param.school_id}/`, config)
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("SMCData", "The result is 200");
        console.log("SMCData", result);

        return result;
      } else {
        console.log("SMCData", "Error");
      }
    });
};

export const getAccountInfo = (params) => {
  return http
    .post("getStudentProfile/", params, config)
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("getAccountInfo", "The result is 200");
        console.log("getAccountInfo", result);

        return result;
      } else {
        console.log("getAccountInfo", "Error");
      }
    });
};

// export const getSchoolDetails = (param)=>{
//   return http.
//   get(`get_school_details/${param.school_id}/`,config)
//   .then(async (result) => {

//     console.log("Fetched Mcq data");
//     console.log(result.data);
//     console.log(result.data.data[0].question_url);

//     let obj = {};
//       let chapterContent;
//       let contentTrans;
//       if (result.data && result.data.data.length > 0) {
//         chapterContent = await getContentFromUrl(result.data.data[0].question_url);
//         contentTrans = await chapterContent.json();
//         obj = {id: result.data.data, result: JSON.parse(contentTrans)};
//         console.log(obj);
//         return obj;
//       }

//   });

// }

// getting link for live session 1
export const GetLiveSession = (SessionStr) => {
  return http
    .get(`get_app_background/?service_name=live_session_${SessionStr}`)
    .then(async (result) => {
      if (result && result.status === 200) {
        return result;
      } else {
        console.log("GetLiveData", "Error");
      }
    });
};

//fucntion for getting classes list available for mcq
// export const getMcqClasses = () => {
//     return http.get("mcq classes endpoint").then(async (result) => {
//         if (result && result.status === 200) {
//             console.log("Classes for MCQ", "The result is 200");
//             console.log("Classes", result);

//             return result;
//         } else {
//             console.log("Classes", "Error");
//         }
//     });
// };

//fucntion for getting subject list from the select class for mcq
export const getMCQReelsSubject = (param) => {
  return http
    .get(
      `get_all_subjects_v2/?class_id=${param?.classId}&is_common_reels=True&medium_id=3`,
      config
    )
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("subject for MCQ", "The result is 200");
        console.log("subject for mcq", result);

        return result;
      } else {
        console.log("subjects", "Error");
      }
    });
};

// get mcq for that selection
export const getMcqQuestions = (param) => {
  let url =
    param?.aiGenrated == true
      ? `mcqquestions/get_questions/?class_id_id=${param?.class_id}&subject_id_id=${param?.subject_id}&medium_id_id=${param?.medium_id}&ai_generated=True`
      : `mcqquestions/get_questions/?class_id_id=${param?.class_id}&subject_id_id=${param?.subject_id}&medium_id_id=${param?.medium_id}`;
  console.log("The MCQ-Param->", "URL", url);
  return httpV2
    .get(url, config)
    .then(async (result) => {
      if (result && result.status === 200) {
        // let mcqObj = result.data.data;
        console.log("MCQ questions", "The result is 200");
        console.log("mcq questions", result);

        return result;
      } else {
        console.log("questions", "Error");
      }
    })
    .catch((error) => {
      return null;
    });
};

export const increaseOnePoint = async (param) => {
  const result = await http.post("add_mcq_points/", param, config);
  if (result && result.status === 200) {
    console.log("point added from data from server", result);
    return result;
  } else {
    console.log("point adding error", "Error");
  }
};

// function for getting live users
export const getLiveUsersList = () => {
  return httpV2
    .get("user_time_spend/get_user_time/", config)
    .then(async (result) => {
      if (result && result.status === 200) {
        console.log("live users present", "The result is 200");
        console.log("live users object", result);

        return result;
      } else {
        console.log("live users error", "Error");
      }
    })
    .catch((error) => {
      return null;
    });
};

// for getting min and max time for live users
export const getLiveUsersMinTime = () => {
  return http
    .get(`get_app_background/?service_name=liveuser_min_time`)
    .then((result) => {
      if (result && result.status == 200) {
        console.log("live users mintime", "The result is 200");
        console.log("live users min time", result);
      }
    })
    .catch((err) => {
      console.log({ err });
    });
};

export const getLiveUsersMaxTime = () => {
  return http
    .get(`get_app_background/?service_name=liveuser_max_time`)
    .then((result) => {
      if (result && result.status == 200) {
        console.log("live users maxtime", "The result is 200");
        console.log("live users max time", result);
      }
    })
    .catch((err) => {
      console.log({ err });
    });
};


export const createAccount = (param) => {
  return http
    .post("v2/createparentprofile/", param, config)
    .then((result) => {
      if (result.data && result.data.message === "success") {
        console.log("Created parent profile successfully", result);
      } else if (result && result.status === 400) {
        console.log(
          "Something has went wrong while creating account",
          result.message
        );
      }
    })
    .catch((err) => {
      console.log(err);
      console.log("Something went wrong while creating account");
    });
};

export const getParentAccountDetails = () => {
  return http
    .get("v2/getparentprofile/", config)
    .then((result) => {
      result = result.data;
      if (result && result.message === "success") {
        console.log("Parent profile existed ->", result.response);
        return result;
      } else {
        console.log("error while getting parent profile");
        return "";
      }
    })
    .catch((err) => {
      console.log("error getting parent profile ->", err);
    });
};

export const getMedium = (param) => {
  return http
    .post("getMediumByState/", param, config)
    .then((result) => {
      result = result.data;
      if (result && result.status === 200) {
        console.log("Mediums ->", result?.response);
        return result;
      } else {
        console.log("error while getting mediums");
      }
    })
    .catch((err) => {
      console.log("error while getting mediums ->", err);
    });
};

export const getSchools = (param) => {
  return http
    .post(`getSchoolsStudentApplication/`, param, config)
    .then((result) => {
      result = result.data;
      if (result && result.status === 200) {
        console.log("Schools ->", result.response);
        return result;
      } else {
        console.log("error getting Schools");
      }
    })
    .catch((err) => {
      console.log("error while getting schools ->", err);
    });
};

export const createStudentProfile = (param) => {
  return http
    .post("v2/createstudentprofile/", param, config)
    .then((result) => {
      result = result.data;
      if (result.message === "success") {
        console.log("Student profile created successfully ->", result.response);
        return "success";
      } else {
        console.log("Error while creating student profile");
      }
    })
    .catch((err) => {
      console.log("Error while creating student profile", err);
    });
};

export const getStudentProfileObj = () => {
  return http.get(`getAllStudentProfile/`, config).then((result) => {
    result = result.data;
    if (result && result.status === 200) {
      console.log(
        "from datafrom server->",
        result.response.studentProfileDetails
      );
      return result.response.studentProfileDetails;
    } else {
      console.log("error setting student profiles");
    }
  });
};

export const getProfileStatus = () => {
  return http
    .get(`getAllStudentProfile/`, config)
    .then((result) => {
      result = result.data;
      if (result && result.status === 200) {
        return result.response;
      } else {
        console.log("error getting status->");
      }
    })
    .catch((err) => {
      console.log("error getting status->", err);
    })};

export const getAIQuestions = (param) => {
  return httpV2
    .post(`passagebank/get_questions_from_passage/`, param, config)
    .then((result) => {
      result = result.data;
      console.log(result);
      if (result && result?.status == 200) {
        console.log("successfully fetched AIQestions");
        return result?.question_list;
      } else {
        console.log("error getting AIQuestions");
      }
    })
    .catch((err) => {
      console.log("error getting AI questions ->", err);
    });
};

// export const getAIQuestion = (params) => {
//   return httpV2
//     .post("passagebank/get_questions_from_passage/", params, config)
//     .then(async (result) => {
//       if (result && result.status === 200) {
//         console.log("GetAIgeneratedQuestion->", "The result is 200");
//         console.log("GetAIgeneratedQuestion->", result);

//         return result;
//       } else {
//         console.log("GetAIgeneratedQuestion->", "Error");
//       }
//     });
// };

export const getUserWiseToppers = (param) => {
  return http
    .post("get_user_wise_topper/", param, config)
    .then((result) => {
      if (result && result.status == 200) {
        result = result.data;
        console.log("list of toppers ->", result.response);
        return result.response;
      } else {
        console.log("got error while getting toppers");
      }
    })
    .catch((err) => {
      console.log("got error while getting toppers->", err);
    });
};
